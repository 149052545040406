import { useEffect, useState, useCallback } from "react";
import { setToLs, getFromLs } from "../utils/storage";
import _ from 'lodash';

export const useTheme = () => {
    const themes = getFromLs('all-themes');
    const [theme, setTheme] = useState(themes[0]);
    const [themeLoaded, setThemeLoaded] = useState(false);

    const setMode = useCallback((mode) => {
        setToLs('theme', mode);
        setTheme(mode);
    }, []);

    const getFonts = useCallback(() => {
        const allFonts = _.values(_.mapValues(themes, 'font'));
        return allFonts;
    }, []);

    useEffect(() => {
        const localTheme = getFromLs('theme');
        localTheme ? setTheme(localTheme) : setTheme(themes[0]);
        setThemeLoaded(true);
    }, []);

    return { theme, themeLoaded, setMode, getFonts };
}
import {useEffect} from 'react';
import TagManager from 'react-gtm-module';
import {getCountryCode} from '../utils/utils';

// Consolidate GTM configurations into an object
const GTMConfig = {
    beko: [
        {country: '*', gtmId: 'GTM-TTL3FBMJ'},
        {country: 'tr', gtmId: 'GTM-N363K6S'},
        {country: 'hu', gtmId: 'GTM-KSGRKF4'},
        {country: 'cz', gtmId: 'GTM-5C5PFNF'},
        {country: 'sk', gtmId: 'GTM-NVSPBD4'},
        {country: 'at', gtmId: 'GTM-K2FNKH2'},
        {country: 'id', gtmId: 'GTM-MRS4FZN'},
        {country: 'it', gtmId: 'GTM-58TF7SV'},
        {country: 'es', gtmId: 'GTM-WPMTJTW'},
        {country: 'my', gtmId: 'GTM-P9N4R36'},
        {country: 'ph', gtmId: 'GTM-5WXV7K2'},
        {country: 'au', gtmId: 'GTM-MJM5MQVH'},
        {country: 'nz', gtmId: 'GTM-N7R645MQ'},
        {country: 'eg', gtmId: 'GTM-MZSNTS6G'},
        {country: 'pl', gtmId: 'GTM-5F8L7V6H'},
        {country: 'sg', gtmId: 'GTM-N7W936SM'},
        {country: 'be', gtmId: 'GTM-5PWG8FJD'},
        {country: 'nl', gtmId: 'GTM-TGQJRH4J'},
        {country: 'us', gtmId: 'GTM-NSJDQM9G'},
        {country: 'de', gtmId: 'GTM-PTW5N5ZN'},
        {country: 'rs', gtmId: 'GTM-T8CHSB76'},
        {country: 'za', gtmId: 'GTM-T6KLBG4T'},
        {country: 'pt', gtmId: 'GTM-P63TXMD9'},
        {country: 'ua', gtmId: 'GTM-WX66JQLB'},
        {country: 'gr', gtmId: 'GTM-TV44DH7B'},
        {country: 'ro', gtmId: 'GTM-N76S37DT'},
        {country: 'md', gtmId: 'GTM-TPMDTWZK'}
    ],
    elektrabregenz: [
        {country: '*', gtmId: 'GTM-TCWGH2XC'},
        {country: 'at', gtmId: 'GTM-5PSZ37NQ'}
    ],
    grundig: [
        {country: '*', gtmId: 'GTM-NHQNSK76'},
        {country: 'ro', gtmId: 'GTM-NSGXVVCB'},
        {country: 'md', gtmId: 'GTM-KGG2668T'}
    ],
    arctic: [
        {country: '*', gtmId: 'GTM-M8SXTKBM'},
        {country: 'ro', gtmId: 'GTM-NDP88Z8J'},
        {country: 'md', gtmId: 'GTM-KWNG9M24'}
    ]
};

export const useTagManager = (brandName) => {
    const countryCode = getCountryCode();

    useEffect(() => {
        const config = GTMConfig[brandName];
        if (!config) return;

        const gtm = config.find((x) => x.country === countryCode) || config.find((x) => x.country === '*');
        if (!gtm) return;

        TagManager.initialize({gtmId: gtm.gtmId});
    }, [brandName, countryCode]);

    return TagManager;
};

import React, { createContext, useContext } from 'react'
import { useTagManager } from '../hooks/use-tag-manager'
import {getDomainnameFromUrl} from "../utils/utils";

const TagManagerContext = createContext();

export const TagManagerProvider = ({ children }) => {
    var brandName = getDomainnameFromUrl();
    const tagManager = useTagManager(brandName);
    return (
        <TagManagerContext.Provider value={tagManager}>
            {children}
        </TagManagerContext.Provider>
    )
}

export const useTagManagerContext = () => {
    return useContext(TagManagerContext);
}
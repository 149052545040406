import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "../assets/css/global.scss";

const LoadingBackground = () => {
  return (
    <div className="parentDisable" style={{ width: "100%" }}>
      <div className="overlay-box">
        <CircularProgress />
      </div>
    </div>
  );
};

export default LoadingBackground;

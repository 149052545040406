import { createSlice } from "@reduxjs/toolkit";

const recaptchaSlice = createSlice({
    name: 'recaptcha',
    initialState: {
        token: {
            response: '',
            version: 2
        },
        recaptchaModalIsVisible: false
    },
    reducers: {
        setVersion(state, action) {
            state.version = action.payload;
            if (action.payload === 1) {
                state.recaptchaModalIsVisible = true;
            }
            else {
                state.recaptchaModalIsVisible = false;
            }
        },
        setToken(state, action) {
            state.token.response = action.payload.response;
            state.token.version = action.payload?.version ? action.payload.version : 2;
        },
        setRecaptchaModalIsVisible(state, action) {
            state.recaptchaModalIsVisible = action.payload;
        },
        toggle(state) {
            state.recaptchaModalIsVisible = !state.recaptchaModalIsVisible;
        }

    }
})

export const recaptchaActions = recaptchaSlice.actions;

export default recaptchaSlice;
import { configureStore } from "@reduxjs/toolkit";
import uiSlice from "./ui-slice";
import productValidationSlice from "./product-validation-slice";
import productSlice from "./product-slice";
import formSlice from "./form-slice";
import giftSlice from "./gift-slice";
import registrationSlice from "./registration-slice";
import generalSlice from "./general-slice";
import recaptchaSlice from "./recaptcha-slice";
import verifySlice from "./verify-slice";
import gatewaySlice from "./gateway-slice";

const store = configureStore({
  reducer: {
    ui: uiSlice.reducer,
    productValidation: productValidationSlice.reducer,
    product: productSlice.reducer,
    form: formSlice.reducer,
    gift: giftSlice.reducer,
    registration: registrationSlice.reducer,
    general: generalSlice.reducer,
    recaptcha: recaptchaSlice.reducer,
    verify: verifySlice.reducer,
    gateway: gatewaySlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

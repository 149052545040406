//export const SERVICE_URL = 'https://localhost:5001/';
export const SERVICE_URL = 'https://app-producetreg-prod.azurewebsites.net/';
export const VALIDATE_PRODUCT = SERVICE_URL + 'api/product/v1/validate';
export const REFRESH_VALIDATED_TOKEN = SERVICE_URL + 'api/product/v1/refresh/token';
export const GET_PRODUCT_DETAILS = SERVICE_URL + 'api/product/v1/details';
export const GET_ASSOCIATED_GIFTS = SERVICE_URL + 'api/gift/v1/product';
export const GET_FORM_FIELDS = SERVICE_URL + 'api/form/v1';
export const GET_REGISTRATION_FORM_DETAILS = SERVICE_URL + 'api/form/v1/get/registration-form/';
export const GENERATE_EMAIL_VALIDATION_CODE = SERVICE_URL + 'api/verify/v1/generate-validation-code';
export const VALIDATE_EMAIL = SERVICE_URL + 'api/verify/v1/secure-validation';
export const SAVE_FORM = SERVICE_URL + 'api/form/v1/save';
export const CHECK_ACTIVE_RECORD = SERVICE_URL + 'api/verify/v1/check/record';
export const CHECK_REGISTRATION = SERVICE_URL + 'api/check/registration';
export const UPDATE_REGISTRATION = SERVICE_URL + 'api/registration/v1/update';
export const UPDATE_REGISTRATION_FILE = SERVICE_URL + 'api/registration/v1/update/file';
export const RESEND_FORGOTTEN_REFERENCE_NUMBER = SERVICE_URL + 'api/registration/v1/resend/certificate';
export const GET_FOOTER_CONFIG = SERVICE_URL + 'api/general/footer/config';
export const RESEND_EMAIL_VALIDATION_CODE = SERVICE_URL + 'api/verify/v1/resend-validation-code';
export const GET_APPLICATION_CONFIG = SERVICE_URL + 'api/application/v1/get/config/';
export const UPDATE_ONE_TRUST_CONFIG = SERVICE_URL + 'api/consent/update/';
export const GET_VALIDATION_TYPE = SERVICE_URL + 'api/verify/v1/type';
export const GET_LIVE_COUNTRIES_BY_BRAND = SERVICE_URL + 'api/gateway/live-countries/';

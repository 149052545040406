import { createSlice } from "@reduxjs/toolkit";

const verifySlice = createSlice({
    name: 'verify',
    initialState: {
        verificationType: 'EMAIL'
    },
    reducers: {
        setVerificationType(state, action) {
            switch (action.payload) {
                case 'EMAIL':
                    state.verificationType = 'EMAIL';
                    return;
                case 'SMS':
                    state.verificationType = 'SMS';
                    return;
                default:
                    state.verificationType = 'EMAIL';
                    return;
            }
        }
    }
})

export const verifyActions = verifySlice.actions;
export default verifySlice;
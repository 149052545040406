import axios from "axios";
import * as urlConfig from '../configs/urlConfig';

class GeneralService {
    getFooterConfigByCountryDetails = (countryCode, languageCode, brandIdentityName) => new Promise((resolve, reject) => {
        axios.get(`${urlConfig.GET_FOOTER_CONFIG}/${countryCode}/${languageCode}/${brandIdentityName}`)
            .then((response) => {
                if (!response.status === 200) {
                    reject(response.data.message);
                }
                else {
                    resolve(response.data);
                }
            }).catch((error) => {
                if (error.response.status === 400) {
                    resolve(error.response.data);
                }
                else {
                    reject(error);
                }
            });
    })

    getApplicationConfig = (languageCode, countryCode) => new Promise((resolve, reject) => {
        let url = urlConfig.GET_APPLICATION_CONFIG;
        if (languageCode && countryCode) {
            url = url + `${languageCode}/${countryCode}`
        }
        axios.get(url)
            .then((response) => {
                if (!response.status === 200) {
                    reject(response.data.message);
                }
                else {
                    resolve(response.data);
                }
            }).catch((error) => {
                if (error.response.status === 400) {
                    resolve(error.response.data);
                }
                else {
                    reject(error);
                }
            });
    })

    updateConsent = (model) => new Promise((resolve, reject) => {
        axios.post(`${urlConfig.UPDATE_ONE_TRUST_CONFIG}`, model)
            .then((response) => {
                if (!response.status === 200) {
                    reject(response.data.message);
                }
                else {
                    resolve(response.data);
                }
            }).catch((error) => {
                if (error.response.status === 400) {
                    resolve(error.response.data);
                }
                else {
                    reject(error);
                }
            });
    })
}

const generalService = new GeneralService();
export default generalService;
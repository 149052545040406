

export const getCurrentDate = (separator = '') => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`}`;
}


export const normalizeProductDetails = entries => {
    let { skuNumber, serialNumber } = Object.fromEntries(entries);
    if (skuNumber && serialNumber) {
        const index = serialNumber.indexOf(skuNumber);
        if (index !== -1) {
            serialNumber = serialNumber.substring(0, index) + serialNumber.substring(index + skuNumber.length);
        }
        else {
            serialNumber = serialNumber.substring(skuNumber.length);
        }

    }

    return { skuNumber, serialNumber };

}


export const getValueByFieldName = (list, name) => {
    return list.find(l => l.fieldName === name)?.value;
}

export const addBlankAttributeToHtmlElement = html => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const links = doc.querySelectorAll('a');

    links.forEach((link) => {
        link.setAttribute('target', '_blank');
    });


    return doc.body.innerHTML;
}


export const getCountryCode = () => {
    const pathName = window.location.pathname;
    return pathName.split('-')[0].replace("/", "");

}

export const getBrandFromPathName = () => {
    const pathName = window.location.pathname;
    return pathName.split('.')[1];
}

export const getDomainnameFromUrl = () => {
    const domain = window.location.hostname;
    return domain.split('.')[1];
}

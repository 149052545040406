import { createSlice } from "@reduxjs/toolkit";

const gatewaySlice = createSlice({
    name: 'gateway',
    initialState: {
        liveCountries: []
    },
    reducers: {
        setLiveCountries(state, action) {
            state.liveCountries = action.payload;
        }
    }
});

export const gatewayActions = gatewaySlice.actions;

export default gatewaySlice;
import React, {
  lazy,
  Suspense,
  Fragment
} from 'react';
import {
  Switch,
  Route
} from 'react-router-dom';
import LangMiddleware from '../components/LangMiddleware';

import LoadingScreen from '../components/LoadingScreen';

import ThemeContext from '../contexts/ThemeContext';
import { TagManagerProvider } from '../contexts/TagManagerContext';



const routesConfig = [
  {
    exact: true,
    path: '/',
    component: lazy(() => import('../views/gateway')),
    guard: LangMiddleware
  },
  {
    exact: true,
    path: '/:countryCode-:languageCode/',
    component: lazy(() => import('../views/homepage')),
    guard: LangMiddleware
  },
  {
    exact: true,
    path: '/:countryCode-:languageCode/check-status',
    component: lazy(() => import('../views/checkstatus')),
    guard: LangMiddleware
  },
  {
    exact: true,
    path: '/:countryCode-:languageCode/check-status/:registrationNumber',
    component: lazy(() => import('../views/registrationstatus')),
    guard: LangMiddleware
  },
  {
    exact: true,
    path: '/:countryCode-:languageCode/:prettyUrl/:callcenter?',
    component: lazy(() => import('../views/registrationsteps')),
    guard: LangMiddleware
  },
];


const renderRoutes = (routes) => (routes ? (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <TagManagerProvider>
                <ThemeContext.Consumer>
                  {(context) => (
                    <Guard>
                      <Layout>
                        {route.routes
                          ? renderRoutes(route.routes)
                          : <Component themeConfig={context.selectedTheme} {...props} />}
                      </Layout>
                    </Guard>
                  )}
                </ThemeContext.Consumer>
              </TagManagerProvider>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
) : null);

function Routes() {
  return renderRoutes(routesConfig);
}

export default Routes;
import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    modalIsVisible: false,
    notification: null,
    isRequestSending: false,
  },
  reducers: {
    toggle(state) {
      state.modalIsVisible = !state.modalIsVisible;
    },
    showNotification(state, action) {
      state.notification = {
        status: action.payload.status,
        title: action.payload.title,
        message: action.payload.message,
      };
      state.modalIsVisible = action.payload.isVisible;
    },
    setRequestStatus(state, action) {
      state.isRequestSending = action.payload.isRequestSending;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice;

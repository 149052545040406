import { createSlice } from "@reduxjs/toolkit";

const generalSlice = createSlice({
    name: 'general',
    initialState: {
        redirectionLink: null,
        applicationConfig : null
    },
    reducers: {
        setRedirectionLink(state, action) {
            state.redirectionLink = action.payload;
        },
        setApplicationConfig(state,action){
            state.applicationConfig = action.payload;
        }
    }
});

export const generalActions = generalSlice.actions;
export default generalSlice;
import HmpgGrundigInformationIcon from "../assets/img/grundig-information.png";
import HmpgGrundigContactIcon from "../assets/img/grundig-contact.png";
import HmpgGrundigReviewIcon from "../assets/img/grundig-review.png";
import StepperGrundigDetailsIcon from "../assets/img/stepper/grundig-stepper-details-icon.png";
import StepperGrundigGiftIcon from "../assets/img/stepper/grundig-stepper-gift-icon.png";
import StepperGrundigFormEditIcon from "../assets/img/stepper/grundig-stepper-form-edit-icon.png";
import StepperGrundigStepperCompletedIcon from "../assets/img/stepper/grundig-stepper-completed.png";
import GrundigRegistrationCompleted from "../assets/img/stepper/grundig-registration-completed.png";

import HmpgBekoInformationIcon from "../assets/img/beko-information.png";
import HmpgBekoContactIcon from "../assets/img/beko-contact.png";
import HmpgBekoReviewIcon from "../assets/img/beko-gift.png";
import StepperBekoDetailsIcon from "../assets/img/stepper/beko-stepper-details-icon.png";
import StepperBekoDetailsActiveIcon from "../assets/img/stepper/beko-stepper-details-active-icon.svg";
import StepperBekoGiftIcon from "../assets/img/stepper/beko-stepper-gift-icon.png";
import StepperBekoActiveGiftIcon from "../assets/img/stepper/beko-stepper-gift-active-icon.png";
import StepperBekoFormEditIcon from "../assets/img/stepper/beko-stepper-form-edit-icon.png";
import StepperCompleted from "../assets/img/stepper/beko-stepper-completed-icon.svg";
import BekoRegistrationCompleted from "../assets/img/stepper/beko-registration-success.png";

import ArcticRegisterationCompleteIcon from "../assets/img/stepper/arctic-registration-completed.svg";

import StepperBlombergInformationIcon from "../assets/img/stepper/blomberg-information.svg";
import StepperBlombergReviewIcon from "../assets/img/stepper/blomberg-review.svg";
import StepperBlombergReviewActiveIcon from "../assets/img/stepper/blomberg-review-active.svg";
import StepperBlombergDetailsIcon from "../assets/img/stepper/blomberg-details.svg";
import StepperBlombergDetailsActiveIcon from "../assets/img/stepper/blomberg-details-active.svg";
import StepperBlombergCompleteIcon from "../assets/img/stepper/blomberg-completed.svg";
import BlombergRegistrationCompleteIcon from "../assets/img/stepper/blomberg-registration-completed.svg";

import LeisureRegisterationCompleteIcon from "../assets/img/stepper/leisure-registration-completed.svg";

import StepperElektrabergzInformationIcon from "../assets/img/stepper/elektrabergz-information.svg";
import StepperElektrabergzReviewIcon from "../assets/img/stepper/elektrabergz-review.svg";
import StepperElektrabergzReviewActiveIcon from "../assets/img/stepper/elektrabergz-review-active.svg";
import StepperElektrabergzDetailsIcon from "../assets/img/stepper/elektrabergz-details.svg";
import StepperElektrabergzDetailsActiveIcon from "../assets/img/stepper/elektrabergz-details-active.svg";
import StepperElektrabergzCompleteIcon from "../assets/img/stepper/elektrabergz-completed.svg";
import ElektrabergzRegisterationCompleteIcon from "../assets/img/stepper/elektrabergz-registration-completed.svg";



import HmpgArcelikInformationIcon from "../assets/img/arcelik-information.png";
import HmpgArcelikContactIcon from "../assets/img/arcelik-contact.png";
import HmpgArcelikReviewIcon from "../assets/img/arcelik-gift.png";
import StepperArcelikDetailsIcon from "../assets/img/stepper/arcelik-stepper-details-icon.png";
import StepperArcelikDetailsActiveIcon from "../assets/img/stepper/arcelik-stepper-details-active-icon.svg";
import StepperArcelikGiftIcon from "../assets/img/stepper/arcelik-stepper-gift-icon.png";
import StepperArcelikActiveGiftIcon from "../assets/img/stepper/arcelik-stepper-gift-icon.png";
import StepperArcelikFormEditIcon from "../assets/img/stepper/arcelik-stepper-form-edit-icon.png";
import ArcelikStepperCompleted from "../assets/img/stepper/arcelik-stepper-completed-icon.png";
import ArcelikRegistrationCompleted from "../assets/img/stepper/arcelik-registration-success.png";




import GrundigOkIcon from "../assets/img/grundig-ok-icon.png";
import BekoOkIcon from "../assets/img/ok-icon.svg";
import ArcticOkIcon from "../assets/img/arctic-ok.png";
import BlombergOkIcon from "../assets/img/blomberg-ok.png";
import LeisureOkIcon from "../assets/img/luisure-ok.png";
import ElektrabergzOkIcon from "../assets/img/elektrabergz-ok.png";

import CardDetailImage1 from "../assets/img/howDoIFindThis/detail1.png";
import CardDetailImage2 from "../assets/img/howDoIFindThis/detail2.png";
import CardDetailImage3 from "../assets/img/howDoIFindThis/detail3.png";
import CardDetailImage4 from "../assets/img/howDoIFindThis/detail4.png";
import CardDetailImage5 from "../assets/img/howDoIFindThis/detail5.png";
import CardDetailImage6 from "../assets/img/howDoIFindThis/detail6.png";
import CardDetailImage7 from "../assets/img/howDoIFindThis/detail7.png";
import CardDetailImage8 from "../assets/img/howDoIFindThis/detail8.png";
import CardDetailImage9 from "../assets/img/howDoIFindThis/detail9.png";
import CardDetailImage10 from "../assets/img/howDoIFindThis/detail10.png";
import CardDetailImage11 from "../assets/img/howDoIFindThis/detail11.png";
import CardDetailImage12 from "../assets/img/howDoIFindThis/detail12.png";
import CardDetailImage13 from "../assets/img/howDoIFindThis/detail13.png";
import CardDetailImage14 from "../assets/img/howDoIFindThis/detail14.png";
import CardDetailImage15 from "../assets/img/howDoIFindThis/detail15.png";

import InfoIcon from '../assets/img/info-solid.svg';


import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

export const THEMES = {
  BEKO: "BEKO",
  GRUNDIG: "GRUNDIG",
  ARCTIC: "ARCTIC",
  BLOMBERG: "BLOMBERG",
  LEISURE: "LEISURE",
  ELEKTRABREGENZ: "ELEKTRABREGENZ",
  ARCELIK: 'ARCELIK'
};

export const themeConfigs = [
  {
    id: THEMES.BEKO,
    name: THEMES.BEKO,
    colors: {
      background: "#0A2033",
      popupColor: "#DBE6ED",
      popupBorder: "1px dashed #0096D7",
      text: "#fff",
      registrationStepsLabel: "#7CD1FA",
      input: {
        borderRadius: "1.6rem",
        border: "1px solid #213e57",
      },
      button: {
        background: "#0096D7",
        text: "#fff",
        checkStatusText: "#fff",
        primary: "#0096D7",
        secondaryText: "#fff",
        secondary: "2px solid rgb(255 255 255 / 30%)",
        display: "none",
        display2: "none",
      },
      checkBox: {
        color: "#0096D7",
      },
      icons: {
        homePageIcons: "#7CD1FA",
        promotion: {
          background: "#15293B",
          text: "#fff",
          border: "1px solid #15293B",
          linear: "linear-gradient(to top right, #15293b 50%, #163046 50%)",
        },
        selectedPromotion: {
          // background: "linear-gradient(to top right, #0091cf 50%, #0096d7 50%)",
          background: "#0096D7",
          text: "#fff",
        },
        circle: {
          background: "",
        },
        selectedCircle: {
          background: "#fff",
        },
      },
      stepper: {
        background: "#213E57",
        activeBackground: "#0096D7",
        completedBackground: "#0096D7",
        completedBorder: "1px solid #0096D7",
        activeBorder: "1px solid #6E8CA5",
        otherStepOpacity: '60%'
      },
      table: {
        text: "#14243A",
        background: "#E6F4FB",
        upload: "#E6F4FB;",
      },
      upload: {
        mainText: "#BB442A",
        subText: "#14243A",
        buttonBackground: "transparent",
        buttonText: "#BB442A",
      },
      line: {
        lineBorder: "1px solid #213E57",
        lineBackground: "#213E57",
      },
      footer: {
        background: "#193146",
        textColor: "#fff",
      },
      referenceContent: {
        background: "#0096D7",
        vector: "transparent transparent #0096D7 transparent",
      },
      content: {
        background: "#0a2033",
        text: "#fff",
      },
    },
    icons: {
      homePage: {
        informationIcon: HmpgBekoInformationIcon,
        reviewIcon: HmpgBekoReviewIcon,
        contactIcon: HmpgBekoContactIcon,
      },
      stepper: {
        detailsIcon: StepperBekoDetailsIcon,
        detailsIconActive: StepperBekoDetailsActiveIcon,
        giftIcon: StepperBekoGiftIcon,
        giftIconActive: StepperBekoActiveGiftIcon,
        formEditIcon: StepperBekoFormEditIcon,
        formEditIconActive: StepperBekoFormEditIcon,
        completedIcon: StepperCompleted,
        activeBorder: "1px solid #6e8ca5",
      },
      registrationCompleted: BekoRegistrationCompleted,
      okIcon: BekoOkIcon,
      infoIcon: InfoIcon
    },
    customStyles: {
      button: {
        borderRadius: "3.6rem",
      },
      footer: {
        fontWeight: "400",
        opacity: "0.6rem",
      },
    },
    font: '"Encode Sans Medium",sans-serif',
    fontWeight: "500",
    logoPath: "/assets/img/beko_logo.png",
    header: "4px solid #51a8e6",
    headerImg: "7rem",
  },
  {
    id: THEMES.GRUNDIG,
    name: THEMES.GRUNDIG,
    colors: {
      background: "#BB442A",
      popupColor: "#EEEDE9",
      popupBorder: "1px dashed #BB442A",
      text: "#FFF",
      registrationStepsLabel: "#C7AC84",
      input: {
        borderRadius: "16px",
        border: "1px solid #fff",
		errorColor: '#fff'
      },
      button: {
        background: "#FFF",
        text: "#BB442A",
        checkStatusText: "#FFF",
        primary: "#fff",
        secondaryText: "#fff",
        secondary: "2px solid #fff",
        display: "none",
        display2: "none",
      },
      checkBox: {
        color: "#C7AD85",
      },
      icons: {
        promotion: {
          background: 'rgba(255, 255, 255, 0.2)',
          text: "#FFF",
          border: "1px solid transparent",
          linear: "linear-gradient(to top right, #C96955 50%, #C5604A 50%)",
        },
        selectedPromotion: {
          // background: "linear-gradient(to top right, #C7AD85 50%, #C7AD85 50%)",
          background: "#C7AC84",
          text: "#fff",
        },
        circle: {
          background: "rgba(255, 255, 255, 0.13)",
        },
        selectedCircle: {
          background: "#fff",
        },
      },
      stepper: {
        background: "#fff",
        activeBackground: "#fff",
        completedBackground: "#fff",
        completedBorder: "1px solid #BB442A",
        activeBorder: "1px solid #fff",
      },
      table: {
        text: "#14243A",
        background: "#EEEDE9",
        upload: "#BB442A",
      },
      upload: {
        mainText: "#BB442A",
        subText: "#14243A",
        buttonBackground: "transparent",
        buttonText: "#BB442A",
      },
      line: {
        lineBorder: "1px solid #fff",
        lineBackground: "#fff",
      },
      footer: {
        background: "#EEEDE9",
        textColor: "#14243A",
      },
      referenceContent: {
        background: "#C7AD85",
        vector: "transparent transparent #C7AD85 transparent",
      },
      content: {
        background: "#BB442A",
        text: "#fff",
      },
    },
    icons: {
      homePage: {
        informationIcon: HmpgGrundigInformationIcon,
        reviewIcon: HmpgGrundigReviewIcon,
        contactIcon: HmpgGrundigContactIcon,
      },
      stepper: {
        detailsIcon: StepperGrundigDetailsIcon,
        detailsIconActive: StepperGrundigDetailsIcon,
        giftIcon: StepperGrundigGiftIcon,
        giftIconActive: StepperGrundigGiftIcon,
        formEditIcon: StepperGrundigFormEditIcon,
        formEditIconActive: StepperGrundigFormEditIcon,
        completedIcon: StepperGrundigStepperCompletedIcon,
        activeBorder: "1px solid #fff",
      },
      registrationCompleted: GrundigRegistrationCompleted,
      okIcon: GrundigOkIcon,
      infoIcon: InfoIcon
    },
    customStyles: {
      button: {
        borderRadius: ".4rem",
      },
      footer: {
        fontWeight: "600",
        opacity: "1",
      },
    },
    font: 'Basics',
    fontWeight: "500",
    logoPath: "/assets/img/grundig.svg",
    header: "4px solid #BB442A",
    headerImg: "9rem",
  },
  {
    id: THEMES.ARCTIC,
    name: THEMES.ARCTIC,
    colors: {
      background: "#fff",
      popupColor: "#fff",
      popupBorder: "1px dashed #97005E",
      text: "#2F3241",
      registrationStepsLabel: "#97005E",
      input: {
        borderRadius: "8px",
        border: "1px solid #ddd",
      },
      button: {
        background: "#8A1D5C",
        text: "#fff",
        checkStatusText: "#fff",
        primary: "#8A1D5C",
        secondaryText: "#8A1D5C",
        secondary: "2px solid #8A1D5C",
        display: "none",
        display2: "none",
      },
      checkBox: {
        color: "#97005E",
      },
      icons: {
        homePageIcons: "#97005E",
        promotion: {
          background: "#97005E",
          text: "#fff",
          border: "1px solid rgba(47, 50, 65, 0.2)",
        },
        selectedPromotion: {
          background: "#97005E",
          text: "#fff",
        },
        circle: {
          background: "#fff",
          border: '1px solid #97005E'
        },
        selectedCircle: {
          background: "#fff",
        },
      },
      stepper: {
        background: "#97005E",
        activeBackground: "#97005E",
        completedBackground: "#97005E",
        completedBorder: "1px solid #97005E",
        activeBorder: "1px solid #97005E",
        otherStepOpacity: '30%'
      },
      table: {
        text: "#1C4167",
        background: "#F5F5F5",
        upload: "#97005E",
      },
      upload: {
        mainText: "#97005E",
        subText: "#2E3242",
        buttonBackground: "transparent",
        buttonText: "#8A1D5C",
      },
      line: {
        lineBorder: "1px solid rgba(47, 50, 65, 0.3)",
        borderLineOpacity: '30%',
        lineBackground: "#2F3241",
      },
      footer: {
        background: "#fff",
        textColor: "#2E3242",
      },
      referenceContent: {
        background: "#97005e",
        vector: "transparent transparent #97005e transparent",
      },
      content: {
        background: "#fff",
        text: "#2F3241",
      },
    },
    icons: {
      homePage: {
        informationIcon: HmpgGrundigInformationIcon,
        reviewIcon: HmpgGrundigReviewIcon,
        contactIcon: HmpgGrundigContactIcon,
      },
      stepper: {
        detailsIcon: StepperBekoDetailsIcon,
        detailsIconActive: StepperBekoDetailsActiveIcon,
        giftIcon: StepperBekoGiftIcon,
        giftIconActive: StepperBekoActiveGiftIcon,
        formEditIcon: StepperBekoFormEditIcon,
        formEditIconActive: StepperBekoFormEditIcon,
        completedIcon: StepperCompleted,
        activeBorder: "1px solid #97005E",
      },
      registrationCompleted: ArcticRegisterationCompleteIcon,
      okIcon: ArcticOkIcon,
      infoIcon: InfoIcon
    },
    customStyles: {
      button: {
        content: "keyboard_arrow_right_icon",
        content2: "keyboard_arrow_left_icon",
        cnt: "^",
      },
      footer: {
        fontWeight: "400",
        opacity: "1",
      },
    },
    font: '"Helvetica", sans-serif',
    fontWeight: "600",
    logoPath: "/assets/img/arctic-logo.png",
    header: "4px solid #97005E",
    headerImg: "9rem",
  },
  {
    id: THEMES.BLOMBERG,
    name: THEMES.BLOMBERG,
    colors: {
      background: "#fff",
      popupColor: "#EEEDE9",
      popupBorder: "1px dashed #BB442A",
      text: "#000",
      registrationStepsLabel: "#CB403C",
      input: {
        borderRadius: 0,
        border: "1px solid #ddd",
        borderColor: "#ddd",
      },
      button: {
        background: "#CB403C",
        text: "#fff",
        checkStatusText: "#CB403C",
        primary: "#CB403C",
        secondaryText: "#CB403C",
        secondary: "2px solid #CB403C",
        display: "none",
        display2: "none",
      },
      checkBox: {
        color: "#CB403C",
      },
      icons: {
        promotion: {
          background: "#CB403C",
          text: "#fff",
          border: "1px solid #ddd",
          linear: "none",
        },
        selectedPromotion: {
          background: "#fff",
          text: "#CB403C",
          border: "1px solid #CB403C",
        },
        circle: {
          background: "rgba(255, 255, 255, 0.13)",
        },
        selectedCircle: {
          background: "#fff",
        },
      },
      stepper: {
        background: "#fff",
        activeBackground: "#fff",
        completedBackground: "#fff",
        completedBorder: "1px solid #CB403C",
        activeBorder: "1px solid #CB403C",
      },
      table: {
        text: "#000",
        background: "#E6E6E6",
        upload: "#CB403C",
      },
      upload: {
        mainText: "#CB403C",
        subText: "#14243A",
        buttonBackground: "transparent",
        buttonText: "#BB442A",
      },
      line: {
        lineBorder: "1px solid #000",
        lineBackground: "#000",
      },
      footer: {
        background: "#fff",
        textColor: "#2E3242",
      },
      referenceContent: {
        background: "#C7AD85",
        vector: "transparent transparent #C7AD85 transparent",
      },
      content: {
        background: "#fff",
        text: "#000",
      },
    },
    icons: {
      homePage: {
        informationIcon: HmpgGrundigInformationIcon,
        reviewIcon: HmpgGrundigReviewIcon,
        contactIcon: HmpgGrundigContactIcon,
      },
      stepper: {
        detailsIcon: StepperBlombergDetailsIcon,
        detailsIconActive: StepperBlombergDetailsActiveIcon,
        giftIcon: StepperBlombergReviewIcon,
        giftIconActive: StepperBlombergReviewActiveIcon,
        formEditIcon: StepperBlombergInformationIcon,
        formEditIconActive: StepperBlombergInformationIcon,
        completedIcon: StepperBlombergCompleteIcon,
        activeBorder: "1px solid #CB403C",
      },
      registrationCompleted: BlombergRegistrationCompleteIcon,
      okIcon: BlombergOkIcon,
      infoIcon: InfoIcon
    },
    customStyles: {
      button: {
        borderRadius: "0px",
      },
      footer: {
        fontWeight: "600",
        opacity: "1",
      },
    },
    font: '"Helvetica"',
    fontWeight: "600",
    logoPath: "/assets/img/blomberg-logo.png",
    header: "4px solid #CB403C",
    headerImg: "9rem",
  },
  //LEISURE
  {
    id: THEMES.LEISURE,
    name: THEMES.LEISURE,
    colors: {
      background: "#000",
      popupColor: "#EEEDE9",
      popupBorder: "1px dashed #BB442A",
      text: "#fff",
      registrationStepsLabel: "#FB6674",
      input: {
        borderRadius: "8px",
        border: "1px solid #ddd",
        borderColor: "#ddd",
      },
      button: {
        background: "#fff",
        text: "#000",
        checkStatusText: "#000",
        primary: "#fff",
        secondaryText: "#000",
        secondaryBg: "#fff",
        // secondary: "2px solid #fff",
        padding: "1px 10px 1px 23px",
        secondaryPd: "1px 23px 1px 10px",
        checkPd: "1px 10px 1px 23px",
        display: "none",
        display2: "inline-block",
        width: "206px",
      },
      checkBox: {
        color: "#FB6674",
      },
      icons: {
        promotion: {
          background: "#FB6674",
          text: "#fff",
          border: "1px solid #2F3241",
          linear: "none",
        },
        selectedPromotion: {
          background: "#fff",
          text: "#FB6674",
          border: "1px solid #FB6674",
        },
        circle: {
          background: "rgba(255, 255, 255, 0.13)",
        },
        selectedCircle: {
          background: "#fff",
        },
      },
      stepper: {
        background: "#fff",
        activeBackground: "#FB6674",
        completedBackground: "#FB6674",
        completedBorder: "1px solid #FB6674",
        activeBorder: "1px solid #FB6674",
      },
      table: {
        text: "#000",
        background: "#F4F4F4",
        upload: "#FB6674",
      },
      upload: {
        mainText: "#BB442A",
        subText: "#14243A",
        buttonBackground: "transparent",
        buttonText: "#BB442A",
      },
      line: {
        lineBorder: "1px solid #fff",
        lineBackground: "#fff",
      },
      footer: {
        background: "#193146",
        textColor: "#fff",
      },
      referenceContent: {
        background: "#C7AD85",
        vector: "transparent transparent #C7AD85 transparent",
      },
      content: {
        background: "#000",
        text: "#fff",
      },
    },
    icons: {
      homePage: {
        informationIcon: HmpgGrundigInformationIcon,
        reviewIcon: HmpgGrundigReviewIcon,
        contactIcon: HmpgGrundigContactIcon,
      },
      stepper: {
        detailsIcon: StepperBekoDetailsIcon,
        detailsIconActive: StepperBekoDetailsActiveIcon,
        giftIcon: StepperBekoGiftIcon,
        giftIconActive: StepperBekoActiveGiftIcon,
        formEditIcon: StepperBekoFormEditIcon,
        formEditIconActive: StepperBekoFormEditIcon,
        completedIcon: StepperCompleted,
        activeBorder: "1px solid #6e8ca5",
      },
      registrationCompleted: LeisureRegisterationCompleteIcon,
      okIcon: LeisureOkIcon,
      infoIcon: InfoIcon
    },
    customStyles: {
      button: {
        borderRadius: "60px",
        content: "'>'",
        content2: "'<'",
      },
      footer: {
        fontWeight: "600",
        opacity: "1",
      },
    },
    font: '"roboto"',
    fontWeight: "500",
    logoPath: "/assets/img/leisureLogo.png",
    header: "4px solid #FB6674",
    headerImg: "9rem",
  },
  //ELEKTRABERGZ
  {
    id: THEMES.ELEKTRABREGENZ,
    name: THEMES.ELEKTRABREGENZ,
    colors: {
      background: "#fff",
      popupColor: "#EEEDE9",
      popupBorder: "1px dashed #BB442A",
      text: "#000",
      registrationStepsLabel: "#DC2E33",
      input: {
        borderRadius: "16px",
        border: "2px solid #ddd",
        borderColor: "#ddd",
      },
      button: {
        background: "#DC2E33",
        text: "#fff",
        checkStatusText: "#DC2E33",
        primary: "#DC2E33",
        secondaryText: "#DC2E33",
        secondary: "2px solid #DC2E33",
        display: "none",
        display2: "none",
      },
      checkBox: {
        color: "#DC2E33",
      },
      icons: {
        promotion: {
          background: "#DC2E33",
          text: "#FFF",
          border: "1px solid #ddd",
          linear: "none",
        },
        selectedPromotion: {
          background: "#DDD",
          text: "#DC2E33",
        },
        circle: {
          background: "rgba(255, 255, 255, 0.13)",
        },
        selectedCircle: {
          background: "#fff",
        },
      },
      stepper: {
        background: "#fff",
        activeBackground: "#fff",
        completedBackground: "#fff",
        completedBorder: "1px solid #DC2E33",
        activeBorder: "1px solid #DC2E33",
        otherStepOpacity: '60%'
      },
      table: {
        text: "#000",
        background: "#ECECEC",
        upload: "#DC2E33",
      },
      upload: {
        mainText: "#DC2E33",
        subText: "#14243A",
        buttonBackground: "transparent",
        buttonText: "#BB442A",
      },
      line: {
        lineBorder: "1px solid #000",
        lineBackground: "#000",
      },
      footer: {
        background: "#fff",
        textColor: "#000",
      },
      referenceContent: {
        background: "#DC2E33",
        vector: "transparent transparent #DC2E33 transparent",
      },
      content: {
        background: "#fff",
        text: "#000",
      },
    },
    icons: {
      homePage: {
        informationIcon: HmpgGrundigInformationIcon,
        reviewIcon: HmpgGrundigReviewIcon,
        contactIcon: HmpgGrundigContactIcon,
      },
      stepper: {
        detailsIcon: StepperElektrabergzDetailsIcon,
        detailsIconActive: StepperElektrabergzDetailsActiveIcon,
        giftIcon: StepperElektrabergzReviewIcon,
        giftIconActive: StepperElektrabergzReviewActiveIcon,
        formEditIcon: StepperElektrabergzInformationIcon,
        formEditIconActive: StepperElektrabergzInformationIcon,
        completedIcon: StepperElektrabergzCompleteIcon,
        activeBorder: "1px solid #DC2E33",
      },
      registrationCompleted: ElektrabergzRegisterationCompleteIcon,
      okIcon: ElektrabergzOkIcon,
      infoIcon: InfoIcon
    },
    customStyles: {
      button: {
        borderRadius: "80px",
      },
      footer: {
        fontWeight: "600",
        opacity: "1",
      },
    },
    font: '"Poppins"',
    fontWeight: "600",
    logoPath: "/assets/img/elektrabregenz-logo.png",
    header: "4px solid #DC2E33",
    headerImg: "16rem",
  },
  {
    id: THEMES.ARCELIK,
    name: THEMES.ARCELIK,
    colors: {
      background: "#FFFFFF",
      popupColor: "#DBE6ED",
      popupBorder: "1px dashed #D93131",
      text: "#222222",
      registrationStepsLabel: "#D93131",
      input: {
        borderRadius: "1.6rem",
        border: "1px solid #ddd",
      },
      button: {
        background: "#D93131",
        text: "#FFFFFF",
        checkStatusText: "#222222",
        primary: "#D93131",
        secondaryText: "#222222",
        secondary: "2px solid rgb(217 49 49 / 30%)",
        display: "none",
        display2: "none",
      },
      checkBox: {
        color: "#D93131",
      },
      icons: {
        homePageIcons: "#D93131",
        promotion: {
          background: "#D93131",
          text: "#fff",
          border: "1px solid #15293B",
          linear: "linear-gradient(to top right, #15293b 50%, #163046 50%)",
        },
        selectedPromotion: {
          // background: "linear-gradient(to top right, #0091cf 50%, #D93131 50%)",
          background: "#15293B",
          text: "#fff",
        },
        circle: {
          background: "",
        },
        selectedCircle: {
          background: "#fff",
        },
      },
      stepper: {
        background: "#FFFFFF",
        activeBackground: "#fff",
        completedBackground: "#fff",
        completedBorder: "1px solid #D93131",
        activeBorder: "1px solid #D93131",
      },
      table: {
        text: "#14243A",
        background: "#E6F4FB",
        upload: "#E6F4FB;",
      },
      upload: {
        mainText: "#BB442A",
        subText: "#14243A",
        buttonBackground: "transparent",
        buttonText: "#BB442A",
      },
      line: {
        lineBorder: "1px solid #213E57",
        lineBackground: "#213E57",
      },
      footer: {
        background: "#D93131",
        textColor: "#fff",
      },
      referenceContent: {
        background: "#D93131",
        vector: "transparent transparent #D93131 transparent",
      },
      content: {
        background: "#FFFFFF",
        text: "#222222",
      },
    },
    icons: {
      homePage: {
        informationIcon: HmpgArcelikInformationIcon,
        reviewIcon: HmpgArcelikReviewIcon,
        contactIcon: HmpgArcelikContactIcon,
      },
      stepper: {
        detailsIcon: StepperArcelikDetailsIcon,
        detailsIconActive: StepperArcelikDetailsActiveIcon,
        giftIcon: StepperArcelikGiftIcon,
        giftIconActive: StepperArcelikActiveGiftIcon,
        formEditIcon: StepperArcelikFormEditIcon,
        formEditIconActive: StepperArcelikFormEditIcon,
        completedIcon: ArcelikStepperCompleted,
        activeBorder: "1px solid #6e8ca5",
      },
      registrationCompleted: ArcelikRegistrationCompleted,
      okIcon: BekoOkIcon,
      infoIcon: InfoIcon
    },
    customStyles: {
      button: {
        borderRadius: "3.6rem",
      },
      footer: {
        fontWeight: "400",
        opacity: "0.6rem",
      },
    },
    font: '"Encode Sans Medium",sans-serif',
    fontWeight: "500",
    logoPath: "/assets/img/arcelik-logo.svg",
    header: "4px solid #B32424",
    headerImg: "16rem",
    margin: "1rem 0 1rem 0rem"
  },
];

export const DB = {
  cards: [
    {
      context: [
        {
          id: 1,
          title: "hdift.refrigerators.detail.first.title.lbl",
          description:
            "hdift.refrigerators.detail.first.content.lbl",
          isReverse: false,
          img: CardDetailImage1,
        },
        {
          id: 2,
          title: "hdift.refrigerators.detail.second.title.lbl",
          description:
            "hdift.refrigerators.detail.second.content.lbl",
          isReverse: true,
          img: CardDetailImage2,
        },
        {
          id: 3,
          title: "hdift.refrigerators.detail.third.title.lbl",
          description:
            "hdift.refrigerators.detail.third.content.lbl",
          isReverse: true,
          img: CardDetailImage3,
        },
        {
          id: 4,
          title: "hdift.refrigerators.detail.fourth.title.lbl",
          description:
            "hdift.refrigerators.detail.fourth.content.lbl",
          isReverse: false,
          img: CardDetailImage4,
        },

        {
          id: 5,
          title: "hdift.refrigerators.detail.fifth.title.lbl",
          description:
            "hdift.refrigerators.detail.fifth.content.lbl",
          isReverse: false,
          img: CardDetailImage5,
        },
      ],
    },

    {
      context: [
        {
          id: 1,
          title: "hdift.washers.dryers.detail.first.title.lbl",
          description:
            "hdift.washers.dryers.detail.first.content.lbl",
          isReverse: true,
          img: CardDetailImage6,
        },
        {
          id: 2,
          title: "hdift.washers.dryers.detail.second.title.lbl",
          description:
            "hdift.washers.dryers.detail.second.content.lbl",
          isReverse: false,
          img: CardDetailImage7,
        },
        {
          id: 3,
          title: "hdift.washers.dryers.detail.third.title.lbl",
          description:
            "hdift.washers.dryers.detail.third.content.lbl",
          isReverse: true,
          img: CardDetailImage8,
        },
      ],
    },

    {
      context: [
        {
          id: 1,
          title: "hdift.cooking.detail.first.title.lbl",
          description:
            "hdift.cooking.detail.first.content.lbl",
          isReverse: true,
          img: CardDetailImage9,
        },
        {
          id: 2,
          title: "hdift.cooking.detail.second.title.lbl",
          description:
            "hdift.cooking.detail.second.content.lbl",
          isReverse: true,
          img: CardDetailImage10,
        },
        {
          id: 3,
          title: "hdift.cooking.detail.third.title.lbl",
          description:
            "hdift.cooking.detail.third.content.lbl",
          isReverse: false,
          img: CardDetailImage11,
        },
        {
          id: 4,
          title: "hdift.cooking.detail.fourth.title.lbl",
          description:
            "hdift.cooking.detail.fourth.content.lbl",
          isReverse: false,
          img: CardDetailImage12,
        },
        {
          id: 5,
          title: "hdift.cooking.detail.fifth.title.lbl",
          description:
            "hdift.cooking.detail.fifth.content.lbl",
          isReverse: true,
          img: CardDetailImage13,
        },
      ],
    },

    {
      context: [
        {
          id: 1,
          title: "hdift.dishwasers.detail.title.lbl",
          description:
            "hdift.dishwasers.detail.content.lbl",
          isReverse: true,
          img: CardDetailImage14,
        },
      ],
    },

    {
      context: [
        {
          id: 1,
          title: "hdift.juicers.blenders.detail.title.lbl",
          description:
            "hdift.juicers.blenders.detail.content.lbl",
          isReverse: false,
          img: CardDetailImage15,
        },
      ],
    },
  ],
};

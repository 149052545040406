import generalService from "../services/generalService";
import { uiActions } from "../store/ui-slice"
import { generalActions } from "../store/general-slice";



export const getFooterConfigByCountryDetails = (countryCode, languageCode, brandIdentityName) => {
    return async (dispatch) => {
        dispatch(
            uiActions.showNotification({
                isVisible: false
            })
        );

        try {
            dispatch(
                uiActions.setRequestStatus({
                    isRequestSending: true
                })
            );
            const responseData = await generalService.getFooterConfigByCountryDetails(countryCode, languageCode, brandIdentityName);
            dispatch(
                generalActions.setRedirectionLink(responseData)
            );
            return responseData;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: 'error',
                    title: 'Error!',
                    message: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    isVisible: true
                })
            );
        } finally {
            dispatch(
                uiActions.setRequestStatus({
                    isRequestSending: false
                })
            )
        }
    }
}


export const getApplicationConfig = (languageCode, countryCode) => {
    return async (dispatch) => {
        dispatch(
            uiActions.showNotification({
                isVisible: false
            })
        );

        try {
            dispatch(
                uiActions.setRequestStatus({
                    isRequestSending: true
                })
            );
            const responseData = await generalService.getApplicationConfig(languageCode, countryCode);
            dispatch(
                generalActions.setApplicationConfig(responseData.data)
            );
            return responseData;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: 'error',
                    title: 'Error!',
                    message: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    isVisible: true
                })
            );
        } finally {
            dispatch(
                uiActions.setRequestStatus({
                    isRequestSending: false
                })
            )
        }
    }
}


export const updateConsentConfig = (model) => {
    return async (dispatch) => {
        dispatch(
            uiActions.showNotification({
                isVisible: false
            })
        );

        try {
            dispatch(
                uiActions.setRequestStatus({
                    isRequestSending: true
                })
            );
            const responseData = await generalService.updateConsent(model);

            return responseData;
        } catch (error) {
            dispatch(
                uiActions.showNotification({
                    status: 'error',
                    title: 'Error!',
                    message: error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message,
                    isVisible: true
                })
            );

            return error.response && error.response.data && error.response.data.message ? error.response.data.message : error.message;
        } finally {
            dispatch(
                uiActions.setRequestStatus({
                    isRequestSending: false
                })
            )
        }
    }
}
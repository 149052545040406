import { createSlice } from "@reduxjs/toolkit";

const productValidationSlice = createSlice({
    name: 'productValidation',
    initialState: {
        token: '',
        expiration: '',
    },
    reducers: {
        setTokenDetails(state, action) {
            state.token = action.payload.token;
            state.expiration = action.payload.expiration;
        }
    }
})

export const productValidationActions = productValidationSlice.actions;

export default productValidationSlice;
import { createSlice } from "@reduxjs/toolkit";


const productSlice = createSlice({
    name: 'product',
    initialState: {
        productData: {
            category: '',
            modelNumber: '',
            productImagePath: '',
            serialNumber: ''
        }
    },
    reducers: {
        setProduct(state, action) {
            state.productData = action.payload.productData;
        }
    }
})

export const productActions = productSlice.actions;

export default productSlice;
import React, { Fragment } from "react";
import { Modal, Box } from "@mui/material";
import "../assets/css/modal.scss";
import CloseIcon from "../assets/img/close-icon.png";
import {
  AlertCircle as AlertCircleIcon,
  CheckCircle as CheckCircleIcon,
  Info as AlertInfoIcon,
} from "react-feather";
import { ScreenDetector } from "../hooks/ScreenDetector";

const NotificationModal = (props) => {
  const { dimensions } = ScreenDetector();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: props.bgColor ? props.bgColor : "background.paper",
    width:
      props.fullWidth && props.fullWidth === true && dimensions.isMobile
        ? "100%"
        : dimensions.isMobile
          ? "95%"
          : "auto",
    border: "2px solid #000",
    boxShadow: 24,
    p: "2rem 1rem 2rem 2rem",
    margin: "auto",
  };

  const getIcon = (status) => {
    const map = {
      error: {
        icon: <AlertCircleIcon size={72} color="red" />,
      },
      success: {
        icon: <CheckCircleIcon size={72} color="green" />,
      },
      info: {
        icon: <AlertInfoIcon size={72} color="#664D03" />,
      },
      warning: {
        icon: <AlertCircleIcon size={72} color="#FFF3CD" />,
      },
    };

    const { icon } = map[status];

    return icon;
  };

  return (
    <Modal open={props.show} onClose={() => props.setShow(false)}>
      <Box
        sx={style}
        className="modal-container"
        id={
          props.children.props.children ===
          "The serial number entered is not valid" && "serial-number-error"
        }
      >
        <div className="modal-header">
          <a onClick={() => props.setShow(false)} className="close-modal-btn">
            <img src={CloseIcon}></img>
          </a>
        </div>
        <div className="modal-body">
          <div className="alert-content">
            {props?.status && (<div className="icon">{getIcon(props.status)}</div>)}
            {props.children}
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default NotificationModal;

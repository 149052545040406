import React from 'react'
import ReCAPTCHA from 'react-google-recaptcha';
import NotificationModal from './NotificationModal';
import { useDispatch } from 'react-redux';
import { recaptchaActions } from '../store/recaptcha-slice';
import { useState } from 'react';
import { useEffect } from 'react';
import { Box, LinearProgress } from '@mui/material';
import LoadingBackground from './CircularLoader';

const RecaptchaVersion2 = props => {
    const timeoutInterval = 2;
    const [popupClosingRemainingTime, setPopupClosingRemainingTime] = useState(timeoutInterval);
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const dispatch = useDispatch();

    const onChange = async token => {
        dispatch(recaptchaActions.setToken({ response: token, version: 1 }))

        setIsTimerRunning(true);


    }

    useEffect(() => {
        let timer;

        if (isTimerRunning) {
            if (popupClosingRemainingTime <= 0) {
                props.setShow();
            } else {
                timer = setInterval(() => {
                    setPopupClosingRemainingTime(popupClosingRemainingTime - 1);
                }, 1000)
            }
        } else {
            clearInterval(timer);
        }

        return () => {
            clearInterval(timer);
        };
    }, [isTimerRunning, popupClosingRemainingTime])

    const loader = () => {
        return (
            <LoadingBackground />
        )
    }


    return (
        <NotificationModal
            show={props.show}
            setShow={props.setShow}
            status={props.status}
        >
            <>
                <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
                    onChange={onChange} />
                {isTimerRunning && (loader())}
            </>
        </NotificationModal>
    )
}

export default RecaptchaVersion2
import { createSlice } from "@reduxjs/toolkit";

const giftSlice = createSlice({
    name: 'gift',
    initialState: {
        warranty: [],
        promotions: [],
        allBenefits: [],
        noPromotion: {}
    },
    reducers: {
        setGifts(state, action) {
            const warranty = action.payload.filter(gift => gift.giftType === "warranty" || gift.giftType === "extwarranty");
            const promotions = action.payload.filter(gift => gift.giftType !== "warranty" && gift.giftType !== "extwarranty");
            const noPromotion = action.payload.find(gift => gift.giftIdentityName === "no-promotion");
            state.warranty = warranty;
            state.promotions = promotions;
            state.noPromotion = noPromotion;
            state.allBenefits = action.payload;
        }
    }
})

export const giftActions = giftSlice.actions;
export default giftSlice;
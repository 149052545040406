import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { getThemeConfig } from "./theme/index";
import { setToLs } from "./utils/storage";
import { themeConfigs as THEMES } from "./constants";
import { BrowserRouter } from "react-router-dom";
import "./assets/css/homepage/main.scss";
import "./assets/css/fonts.scss";
import { Provider } from "react-redux";
import store from "./store/index";
import "./i18nextConf";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


const Index = () => {
  setToLs("all-themes", THEMES);
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} scriptProps={{ async: true }}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </GoogleReCaptchaProvider>
  );
};

ReactDOM.render(<Index />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

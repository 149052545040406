import React from 'react'
import { Modal as ModalMui, Box } from '@mui/material'
import '../assets/css/modal.scss'
import CloseIcon from '../assets/img/close-icon.png'
import { ScreenDetector } from '../hooks/ScreenDetector'


const Modal = ({ show, setShow, content, bgColor, fullWidth, smallModal }) => {
    const { dimensions } = ScreenDetector();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: fullWidth && fullWidth === true && (dimensions.isMobile) ? '100%' : dimensions.isMobile ? '95%' : 'auto',
        bgcolor: bgColor ? bgColor : 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: '2rem 1rem 2rem 2rem'
    };

    return (
        <ModalMui
            open={show}
            onClose={() => setShow(false)}
            aria-labelledby="modal-title"
            aria-describedby="modal-description">
            
            <Box className='modal-container' sx={style}>
                <div className='modal-header'>
                    <a onClick={() => setShow(false)} className='close-modal-btn'>
                        <img src={CloseIcon}></img>
                    </a>
                </div>
                <div className='modal-body'>
                    {content && content}
                </div>
            </Box>
        </ModalMui>
    )
}

export default Modal
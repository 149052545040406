import React, { useState } from 'react'

export const ScreenDetector = () => {
    const [dimensions, setDimensions] = useState({
        height: window.screen.height,
        width: window.screen.width,
        isMobile: window.screen.width < 575.98 ? true : false,
        isTablet: window.screen.width >= 575.98 && window.screen.width < 767.98,
        isSmall : window.screen.width >= 767.98 && window.screen.width < 1024,
        isMediumScreen: window.screen.width >= 1024 && window.screen.width < 1440.1,
        isLargeScreen: window.screen.width >= 1440.1,
    });

    function debounce(fn, ms) {
        let timer
        return _ => {
            clearTimeout(timer)
            timer = setTimeout(_ => {
                timer = null
                fn.apply(this, arguments)
            }, ms)
        };
    }

    React.useEffect(() => {
        const debouncedHandleResize = debounce(function handleResize() {
            setDimensions({
                height: window.screen.height,
                width: window.screen.width,
                isMobile: window.screen.width < 575.98 ? true : false,
                isTablet: window.screen.width >= 575.98 && window.screen.width < 767.98,
                isSmall : window.screen.width >= 767.98 && window.screen.width < 1024,
                isMediumScreen:window.screen.width >= 1024 && window.screen.width < 1440.1,
                isLargeScreen: window.screen.width >= 1440.1,
            })
        })

        window.addEventListener('resize', debouncedHandleResize)
        return _ => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    })

    return { dimensions, setDimensions };
}


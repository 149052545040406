import { createSlice } from "@reduxjs/toolkit";

const formSlice = createSlice({
  name: "form",
  initialState: {
    formDetails: {
      formName: "",
      formId: "",
      brandIdentityName: "",
      prettyUrl: "",
      countryId: "",
      fields: [],
      contactFields: [],
      isRegistrationForm: false,
      consentVariantId: 0
    },
    supportedLanguages: []
  },
  reducers: {
    setForm(state, action) {
      state.formDetails.formName = action.payload.formName;
      state.formDetails.formId = action.payload.formId;
      state.formDetails.prettyUrl = action.payload.prettyUrl;
      state.formDetails.countryId = action.payload.countryId;
      if (!action.payload.isContactStep) {
        state.formDetails.fields = action.payload.fields;
      }
      state.formDetails.contactFields = action.payload.fields;
      state.formDetails.brandIdentityName = action.payload.brandIdentityName;
      state.formDetails.isRegistrationForm = action.payload.isRegistrationForm;
      state.formDetails.consentVariantId = action.payload.consentVariantId;
    },
    resetForm(state) {
      state.formDetails.formName = "";
      state.formDetails.formId = "";
      state.formDetails.prettyUrl = "";
      state.formDetails.countryId = "";
      state.formDetails.fields = [];
      state.formDetails.contactFields = [];
      state.formDetails.brandIdentityName = "";
      state.formDetails.isRegistrationForm = false;
      state.formDetails.consentVariantId = 0;
    },
    onChange(state, action) {
      const currentFields = state.formDetails.fields;
      let currentContactFields = state.formDetails.contactFields;
      var field = currentFields.find(
        (field) => field.fieldName === action.payload.name
      );
      var contactField = {};

      if (action.payload.name === "dialCode") {

        var dialCodeObj = currentContactFields.find(x => x.fieldName === action.payload.name);

        if (!dialCodeObj) {
          contactField = {
            name: action.payload.name,
            isDefaultFied: true,
            isRequired: false,
            fieldName: action.payload.name
          }

          currentContactFields = [...currentContactFields, contactField];
        }

        else {
          contactField = dialCodeObj;
        }
      }
      else {
        contactField = currentContactFields.find(
          (field) => field.fieldName === action.payload.name
        );
      }


      if (field) {
        field["fieldValue"] = action.payload.value;
        state.formDetails.fields = currentFields;
      } else if (contactField) {
        contactField["fieldValue"] = action.payload.value;
        state.formDetails.contactFields = currentContactFields;
      }



    },
    setSupportedLanguages(state, action) {
      const payload = action.payload;
      if (payload?.languages) {
        state.supportedLanguages = payload.languages;
      }
    }
  },
});

export const formActions = formSlice.actions;

export default formSlice;

import styled from "styled-components";
import ThemeProvider from "./providers/ThemeProvider";
import { useDispatch } from "react-redux";
import { uiActions } from "./store/ui-slice";
import { Route } from "react-router-dom";
import Routes from "./routes/Routes";
import Notification from "./components/ui/Notification";
import { useSelector } from "react-redux";
import LoadingBackground from "./components/CircularLoader";
import React from "react";
import RecaptchaVersion2 from "./components/RecaptchaVersion2";
import { recaptchaActions } from "./store/recaptcha-slice";
import { SnackbarProvider } from 'notistack'
const Container = styled.div``;

function App() {
  const Logo = styled.img.attrs((props) => ({
    src: props.src,
  }))``;

  const dispatch = useDispatch();

  const toggleModal = () => {
    dispatch(uiActions.toggle());
  };

  const toggleRecaptchaModal = () => {
    dispatch(recaptchaActions.toggle());
  }

  const notification = useSelector((state) => state.ui.notification);
  const modalIsVisible = useSelector((state) => state.ui.modalIsVisible);
  const isRequestSending = useSelector((state) => state.ui.isRequestSending);
  const recaptchaModalIsVisible = useSelector((state) => state.recaptcha.recaptchaModalIsVisible);


  return (
    <>
      <ThemeProvider>
        <SnackbarProvider maxSnack={2}>
          <Container>
            {modalIsVisible && (
              <Notification
                status={notification.status}
                title={notification.title}
                message={notification.message}
                show={modalIsVisible}
                setShow={toggleModal}
              />
            )}
            {recaptchaModalIsVisible && (
              <RecaptchaVersion2
                status=''
                title=''
                message=''
                show={recaptchaModalIsVisible}
                setShow={toggleRecaptchaModal}
              />
            )}
            {isRequestSending && <LoadingBackground />}
            <Route path="/" component={Routes} />
          </Container>
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;

import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getApplicationConfig } from '../actions/general-actions';
import ThemeContext from '../contexts/ThemeContext';
import { getThemeConfig } from '../theme';
import { useTheme } from '../theme/useTheme';
import LoadingScreen from "./LoadingScreen";

const LangMiddleware = ({ children }) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const { setMode } = useTheme();
    const { setSelectedTheme } = useContext(ThemeContext);
    const { languageCode, countryCode } = useParams();
    const { i18n } = useTranslation();
    const dispatch = useDispatch();

    const init = useCallback(async () => {
        if (languageCode && countryCode) {
            i18n.changeLanguage(`${languageCode}-${countryCode}`);
        }

        const result = await dispatch(getApplicationConfig(languageCode || '', countryCode || ''));

        if (result.success) {
            const theme = getThemeConfig(result.data.name);
            setMode(theme);
            setSelectedTheme(theme);
            document.title = result.data.applicationPageTitle;
        }

        setIsInitialized(true);
    }, [languageCode, countryCode, i18n, dispatch, setMode, setSelectedTheme]);

    useEffect(() => {
        init();
    }, [init]);

    if (!isInitialized) {
        return <LoadingScreen />;
    }

    return children;
};

export default LangMiddleware;
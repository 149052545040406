import { themeConfigs } from "../constants";

export const getThemeConfig = (name) => {
  let themeConfig = themeConfigs.find((theme) => theme.name === name);
  if (!themeConfig) {
    alert(`${name} not valid`);
    [themeConfig] = themeConfigs[0];
  }
  return themeConfig;
};

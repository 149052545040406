import Modal from "../Modal";
import NotificationModal from "../NotificationModal";
import classes from "./Notification.module.css";
import React from "react";
import { useTranslation } from "react-i18next";
const Notification = (props) => {
  const { t } = useTranslation();

  return (
    <NotificationModal
      show={props.show}
      setShow={props.setShow}
      status={props.status}
    >
      <div className="information-content">{t(props.message)}</div>
    </NotificationModal>
  );
};

export default Notification;

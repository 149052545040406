import { createGlobalStyle } from "styled-components";
export const GlobalStyles = createGlobalStyle`
*,h1, h2, h3, h4, h5, h6, p, div, span {
  font-family: ${({ theme }) => theme.font}!important;
}
h1, h2, h3, h4, h5, h6,p, span ,.input-group label,a,.registration-count ,.h-text,.btn {
  font-weight: ${({ theme }) => theme.fontWeight}!important;
}
 .right-content {
  background: ${({ theme }) => theme.colors.background} !important;
  color: ${({ theme }) => theme.colors.text} !important;
  transition: all 0.50s linear !important;
 }
.header-container{
 border-top:${({ theme }) => theme.header} !important;
 .header img{
  max-width:${({ theme }) => theme.headerImg} !important;
  margin : ${({ theme }) => theme.margin} !important;
 }
}
 .registration-count{
   color : ${({ theme }) => theme.colors.registrationStepsLabel} !important;
 }
 .btn-primary{
   background :${({ theme }) => theme.colors.button.background} !important;
   color : ${({ theme }) => theme.colors.button.text} !important;
   border-radius : ${({ theme }) => theme.customStyles.button.borderRadius} !important;
padding:${({ theme }) => theme.colors.button.padding}!important;
  
 } 
 //arctic
 .btn-primary::after ,.btn-arctic::after{
    background:${({ theme }) => theme.colors.button.text} !important;
    color: ${({ theme }) => theme.colors.button.background} !important;
    width: 25px !important;
    height:25px !important;
    padding:5px !important;
    font-size: 16px !important;
   margin-left:10px !important;
    border-radius: 50% !important;
    display: ${({ theme }) => theme.colors.button.display} !important;
    font-family: 'Material Icons';
    content: "${({ theme }) => theme.customStyles.button.content}" !important;
    -webkit-font-feature-settings: 'liga';
   }
  
   //leisure
   .btn-primary::after ,.btn-leisure::after {
    content: ${({ theme }) => theme.customStyles.button.content}${({ theme }) =>
    theme.customStyles.button.cnt}!important;
    color: #FB6674${({ theme }) => theme.customStyles.button.cnt} !important;
    background:#fff${({ theme }) => theme.customStyles.button.cnt} !important;
    font-size: 20px${({ theme }) => theme.customStyles.button.cnt} !important;
    margin-left:10px${({ theme }) => theme.customStyles.button.cnt} !important;
    margin-bottom:8px${({ theme }) => theme.customStyles.button.cnt} !important;
     display: ${({ theme }) => theme.colors.button.display2}${({ theme }) =>
    theme.customStyles.button.cnt}!important;

   }


 .btn-secondary{
  border: ${({ theme }) => theme.colors.button.secondary}!important;
   color : ${({ theme }) => theme.colors.button.secondaryText} !important;
   margin-right:1rem !important;
padding:${({ theme }) => theme.colors.button.padding}!important;
background:${({ theme }) => theme.colors.button.secondaryBg}!important;
padding:${({ theme }) => theme.colors.button.secondaryPd}!important;

 }
 
 //arctic
 .btn-secondary::before{
    content: ${({ theme }) => theme.customStyles.button.content2} !important;
    background:${({ theme }) => theme.colors.button.text} !important;
    color: ${({ theme }) => theme.colors.button.background} !important;
    width: 25px !important;
    height:25px !important;
    padding:5px !important;
    font-size: 16px;
   margin-right:10px !important;
    border-radius: 50% !important;
    display: ${({ theme }) => theme.colors.button.display}!important;
   }
//leisure
 .btn-secondary::before{
    content: ${({ theme }) => theme.customStyles.button.content2}${({
      theme,
    }) => theme.customStyles.button.cnt};
   margin-right:10px${({ theme }) => theme.customStyles.button.cnt} !important;
    border-radius: 50%${({ theme }) => theme.customStyles.button.cnt} !important;
    color: #FB6674${({ theme }) => theme.customStyles.button.cnt} !important;
    background:#fff${({ theme }) => theme.customStyles.button.cnt} !important;
    font-size: 20px${({ theme }) => theme.customStyles.button.cnt} !important;
    margin-bottom:8px${({ theme }) => theme.customStyles.button.cnt} !important;
     display: ${({ theme }) => theme.colors.button.display2}${({ theme }) =>
    theme.customStyles.button.cnt}!important;
   }
   .btn-leisure{
  width:${({ theme }) => theme.colors.button.width} !important;
padding:${({ theme }) => theme.colors.button.checkPd}!important;

}

 .btn-arctic::before ,.btn-leisure::before{
    display:none!important;
   }
 .btn-transparent{
  border-radius : ${({ theme }) => theme.customStyles.button.borderRadius} !important
 }
 .button-group .register-text{
color:${({ theme }) => theme.colors.button.primary} !important;
 }
 .reference-content p{
  color:#fff!important;
 }
 a {
  color: ${({ theme }) => theme.colors.text} !important;
  cursor: pointer !important;
 }
 button {
  border: 0;
  display: inline-block;
  padding: 12px 24px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 5px;
  cursor: pointer;
  background-color: #1064EA;
  color: #FFFFFF;
  font-family: ${({ theme }) => theme.font} !important;
 }
 
 .button .btn {
  background-color: ${({ theme }) => theme.colors.button.primary} !important;
  color: ${({ theme }) => theme.colors.text} !important;
 }
 .right-content{
  color : ${({ theme }) => theme.colors.text} !important;
 }
 .content{
  background-color: ${({ theme }) => theme.colors.background} !important;
 }
 .modal-container{
  background-color: ${({ theme }) => theme.colors.popupColor} !important;
 }

 .modal-container .modal-header a{
  border : ${({ theme }) => theme.colors.stepper.activeBorder} !important;
 }

 .information-table:nth-child(odd) {
  background-color: ${({ theme }) => theme.colors.table.background} !important;
 }
 .information-table .tdata,.information-table .trow,.info-label{
  color : ${({ theme }) => theme.colors.table.text} !important;
 }
 .information-table .tdata a{
  color : ${({ theme }) => theme.colors.table.upload} !important;
 }
 .promotion-card{
  background : ${({ theme }) => theme.colors.icons.promotion.background} !important;
  border : ${({ theme }) => theme.colors.icons.promotion.border} !important;
 }



 .promotion-card-content input[type="radio"]:checked + .linear {
  background-image: ${({ theme }) =>
    theme.colors.icons.selectedPromotion.background} !important;
  
 }
 .promotion-card-content input[type=radio]:checked + label{
  background-color : ${({ theme }) => theme.colors.icons.selectedPromotion.background} !important;
    
    .promotion-card-label{
      color:${({ theme }) => theme.colors.icons.selectedPromotion.text} !important;
    }
  .selected-circle{
   background-color : ${({ theme }) => theme.colors.icons.selectedCircle.background} !important;
  }
 }
 .selected-circle{
  background-color : ${({ theme }) => theme.colors.icons.circle.background} !important;
  border:${({ theme }) => theme.colors.icons.circle.border} !important;
 }

 .warranty {
  
.promotion-card-content input[type=radio]:checked + label{
  background-color : ${({ theme }) => theme.colors.icons.selectedPromotion.background} !important;
  background-image: none!important;
  .promotion-card-label{
    color:${({ theme }) => theme.colors.icons.selectedPromotion.text} !important;
  }
  .selected-circle{
     background-color : ${({ theme }) => theme.colors.icons.selectedCircle.background} !important;
  }
 }
}
 .linear{
  background-image: ${({ theme }) =>
    theme.colors.icons.promotion.linear} !important;
 }
 .stepper-completed{
  border : ${({ theme }) => theme.colors.stepper.completedBorder} !important;
 }
 
 .registration-content .horizontal-line{
  border : ${({ theme }) => theme.colors.line.horizontalLine} !important;
 }
 
 .h-text-bordered{
  border-bottom : ${({ theme }) => theme.colors.line.lineBorder} !important;
 }
 .registration-content .vertical-line{
  background-color : ${({ theme }) => theme.colors.line.lineBackground} !important;
  opacity : ${({ theme }) => theme.colors.line.borderLineOpacity};
 }
 .MuiStepConnector-line{
  border : ${({ theme }) => theme.colors.line.borderLine} !important;
 }
 .reference-content{
  background : ${({ theme }) =>
    theme.colors.referenceContent.background} !important;
 }
 .reference-content .vector{
  border-color : ${({ theme }) =>
    theme.colors.referenceContent.vector} !important;
 }
 .footer{
  background : ${({ theme }) => theme.colors.footer.background} !important;
 }
 .f-content .contact-content * , .f-content .more-support *{
  color : ${({ theme }) => theme.colors.footer.textColor} !important;
  font-weight :${({ theme }) =>
    theme.customStyles.footer.fontWeight} !important;
  opacity :${({ theme }) => theme.customStyles.footer.opacity} !important;
 }
 .upload-container{
  border : ${({ theme }) => theme.colors.popupBorder} !important;
 }
 .MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate{
  color : ${({ theme }) => theme.colors.checkBox.color} !important;
 }
.stepper-label-active .MuiStepLabel-iconContainer {
 border: ${({ theme }) => theme.colors.stepper.activeBorder}!important ;
 padding: 4px;
 border-radius: 100%;
}
.MuiStepConnector-lineVertical {
  border-right: ${({ theme }) => theme.colors.line.lineBorder}!important;
  opacity : ${({ theme }) => theme.colors.line.borderLineOpacity};
}
 .product-details{
   border: ${({ theme }) => theme.colors.input.border};
  border-radius:${({ theme }) => theme.colors.input.borderRadius}!important;
  .h-sub-text{
  color: ${({ theme }) => theme.colors.table.text}!important;

  }
 }
.h-text ,.h-sub-text{
  color: ${({ theme }) => theme.colors.content.text}!important;
}
.MuiStepLabel-label {
  color: ${({ theme }) => theme.colors.content.text}!important;
  
}

.input-group label, .input-group a, .upload-group span{
  color: ${({ theme }) => theme.colors.content.text}!important;
}
.modal .center-content .input-group label,.modal .center-content .input-group a{
  color:#000!important;
}
.upload-group span,.file-upload{
  color: ${({ theme }) => theme.colors.table.text}!important;
}
.input-group input, .input-group select, .input-group textarea {
  border: ${({ theme }) => theme.colors.input.border};
  border-radius:${({ theme }) => theme.colors.input.borderRadius}!important;
  color:${({ theme }) => theme.colors.table.text}!important;
}
.MuiFormControl-root-MuiTextField-root,.MuiFormControl-root{
  border-radius:${({ theme }) => theme.colors.input.borderRadius}!important;

}
.input-group .checkbox-label{
  color: ${({ theme }) => theme.colors.content.text}!important;
  font-weight: 500!important;
}
.upload label{
  font-weight: 500!important;

}
.checkbox-label .checkmark{
  border: ${({ theme }) => theme.colors.input.border}!important;
  color:${({ theme }) => theme.colors.content.text}!important;
  
}
.react-tel-input .flag-dropdown{
  border-radius:${({ theme }) => theme.colors.input.borderRadius} 0 0 ${({
      theme,
    }) => theme.colors.input.borderRadius} !important;
}
.MuiOutlinedInput-notchedOutline{
 border:none
}
.MuiOutlinedInput-root{
   border: ${({ theme }) => theme.colors.input.border};
  border-radius:${({ theme }) => theme.colors.input.borderRadius}!important;
  color:${({ theme }) => theme.colors.table.text}!important;
}
.MuiOutlinedInput-root input{
   border:none!important;
}
 .MuiTypography-root.Mui-selected, .MuiButtonBase-root-MuiPickersDay-root.Mui-selected, .Mui-selected {
    background:${({ theme }) => theme.colors.checkBox.color}!important;
}
.content {
  background-color: ${({ theme }) => theme.colors.content.background}!important;
}
.registration-icon{
 fill:${({ theme }) => theme.colors.registrationStepsLabel} !important
}
.upload,.upload-group{
  border: ${({ theme }) => theme.colors.input.border}!important;
  border-radius:${({ theme }) => theme.colors.input.borderRadius}!important;
}
.MuiAvatar-root.MuiAvatar-colorDefault {
  border: ${({ theme }) => theme.colors.stepper.activeBorder}!important;
}

.MuiStepLabel-root.MuiStepLabel-vertical.Mui-disabled {
  opacity : ${({ theme }) => theme.colors.stepper.otherStepOpacity}!important;
}

.checkbox-label .checkmark:after{
 border: solid ${({ theme }) => theme.colors.checkBox.color}!important;
 border-width:0 3px 3px 0!important;
}
.promotion-card-label{
  color:${({ theme }) => theme.colors.icons.promotion.text}!important;
}
.upload-group span,.upload .file-upload{
  color: ${({ theme }) => theme.colors.table.text}!important;
}
.MuiCircularProgress-svg {
    color: ${({ theme }) => theme.colors.checkBox.color}!important;
}
.input-group input.input-error {
  border-color: ${({ theme }) => theme.colors.input.errorColor};
}
.error {
  color: ${({ theme }) => theme.colors.input.errorColor};
}
`;

import { createSlice } from "@reduxjs/toolkit";


const registrationSlice = createSlice({
    name: 'registration',
    initialState: {
        registrationModel: {
            productInformation: [],
            promotions: [],
            contactDetails: [],
            consentTypes: []
        },
        selectedGift: '',
        referenceNumber: '',
        registrationStatus: {},
    },
    reducers: {
        setProductInformation(state, action) {
            var currProdInf = [...state.registrationModel.productInformation];
            var field = state.registrationModel.productInformation.find(x => x.fieldName === action.payload.fieldName);
            if (!field) {
                currProdInf[action.payload.fieldName] = action.payload.value;
                state.registrationModel.productInformation.push({
                    fieldName: action.payload.fieldName,
                    value: action.payload.value,
                    formFieldId: action.payload.formFieldId,
                    fieldType: action.payload.fieldType
                });
            }
            else {
                field['value'] = action.payload.value;
                state.registrationModel.productInformation = currProdInf;
            }
        },
        setPromotions(state, action) {
            var field = state.registrationModel.promotions.find(x => x.promotionName === action.payload.promotionName);
            if (action.payload.promotionType === "Normal") {
                state.selectedGift = action.payload.giftType;
                var currPromotions = [...state.registrationModel.promotions];
                var promotion = currPromotions.find(x => x.promotionType === action.payload.promotionType);
                if (promotion != null) {
                    state.registrationModel.promotions = currPromotions.filter(x => x.promotionType !== action.payload.promotionType);
                }
            }

            var savedPromotions = [...state.registrationModel.promotions];
            if (!field) {
                savedPromotions[action.payload.promotionName] = action.payload.promotionName;
                state.registrationModel.promotions.push(action.payload);
            }
            else {
                field['value'] = action.payload.promotionName;
                state.registrationModel.promotions = savedPromotions;
            }

        },
        setContactDetails(state, action) {
            var currConsentTypes = [];


            if (action.payload.fieldName.includes('consent-')) {
                currConsentTypes = [...state.registrationModel.consentTypes];
                var consentType = currConsentTypes.find(x => x.fieldName === action.payload.fieldName);
                if (!consentType) {
                    state.registrationModel.consentTypes.push({
                        fieldName: action.payload.fieldName,
                        fieldValue: action.payload.value,
                        formFieldId: action.payload.formFieldId,
                        fieldType: action.payload.fieldType,
                    })
                }
                else {
                    consentType.fieldValue = action.payload.value;
                    state.registrationModel.consentTypes = currConsentTypes;
                }
            }
            else {
                var currProdInf = [...state.registrationModel.contactDetails];
                var field = state.registrationModel.contactDetails.find(x => x.fieldName === action.payload.fieldName);
                if (!field) {
                    currProdInf[action.payload.fieldName] = action.payload.value;
                    state.registrationModel.contactDetails.push({
                        fieldName: action.payload.fieldName,
                        value: action.payload.value,
                        formFieldId: action.payload.formFieldId,
                        fieldType: action.payload.fieldType
                    });
                }
                else {
                    field['value'] = action.payload.value;
                    state.registrationModel.contactDetails = currProdInf;
                }
            }
        },
        setReferenceNumber(state, action) {
            state.referenceNumber = action.payload.referenceNumber;
        },
        setRegistrationStatus(state, action) {
            state.registrationStatus = action.payload;
        },
        clearModel(state) {
            state.registrationModel = {
                productInformation: [],
                promotions: [],
                contactDetails: [],
                consentTypes: []
            };
            state.referenceNumber = '';
            state.selectedGift = '';
            state.registrationStatus = {};
        }
    }
})

export const registrationActions = registrationSlice.actions;
export default registrationSlice;